<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="invoice-page">
    <div class="flex flex-wrap items-center justify-end">
      <div v-if="ipcRenderer" class="flex items-center">
        <vs-button size="small" @click="print" :disabled="!saleData"
          >Print</vs-button
        >
      </div>
    </div>

    <vs-tabs>
      <vs-tab label="Receipt">
        <vx-card id="invoice-container">
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-full md:w-1/2 mt-base">
              <img
                :src="
                  !$root.logo ? '@/assets/images/logo/logo.png' : $root.logo
                "
                height="180px"
                alt=""
              />
            </div>
            <div class="vx-col w-full md:w-1/2 text-right">
              <h1>Receipt</h1>
              <div class="invoice__invoice-detail mt-6">
                <h6>RECEIPT ID</h6>
                <p>
                  {{
                    this.$route.params.id
                      ? this.$route.params.id.split(':')[1]
                      : ''
                  }}
                </p>

                <h6 class="mt-4">RECEIPT DATE</h6>
                <p>
                  {{
                    saleData ? parseDate(getDate(saleData.date_created)) : ''
                  }}
                </p>

                <!-- <h6 v-if="!saleData.credit" class="mt-4">PAYMENT VIA</h6>
                <p v-if="!saleData.credit">{{ saleData ? saleData.payment.name : "" }}</p> -->

                <h6 class="mt-4">STATUS</h6>
                <p
                  :style="[
                    saleData && saleData.void
                      ? { color: 'grey' }
                      : saleData && saleData.credit
                        ? { color: 'red' }
                        : { color: 'green' },
                    { fontWeight: '800' },
                  ]"
                >
                  {{
                    saleData && saleData.void
                      ? 'VOID'
                      : saleData && saleData.credit
                        ? 'UNPAID'
                        : 'PAID'
                  }}
                </p>
              </div>
            </div>
            <div
              class="vx-col w-full md:w-1/2 mt-12"
              v-if="saleData && saleData.customer"
            >
              <h5>Customer</h5>
              <div v-if="typeof saleData == 'object'">
                <div
                  v-if="saleData.customer"
                  class="invoice__recipient-info my-4"
                >
                  <p>{{ saleData.customer.name }}</p>
                  <p>{{ saleData.customer.address }}</p>
                  <p>{{ saleData.customer.city }}</p>
                </div>
                <div class="invoice__recipient-contact">
                  <p class="flex items-center">
                    <feather-icon
                      icon="PhoneIcon"
                      svgClasses="h-4 w-4"
                    ></feather-icon>
                    <span class="ml-2">{{ saleData.customer.phone }}</span>
                  </p>
                </div>
              </div>
              <div v-if="typeof saleData == 'array'">
                <div
                  v-if="saleData.customer"
                  class="invoice__recipient-info my-4"
                >
                  <p>{{ saleData.customer[0].name }}</p>
                  <p>{{ saleData.customer[0].address }}</p>
                  <p>{{ saleData.customer[0].city }}</p>
                </div>
                <div class="invoice__recipient-contact">
                  <p class="flex items-center">
                    <feather-icon
                      icon="PhoneIcon"
                      svgClasses="h-4 w-4"
                    ></feather-icon>
                    <span class="ml-2">{{ saleData.customer[0].phone }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-12" v-else></div>
            <div
              v-if="$root.businessData"
              class="vx-col w-full md:w-1/2 text-right mt-12"
            >
              <h5>
                {{ $root.businessData.data.business_name }}
                {{ saleData ? `- ${saleData.branch.branch_name}` : '' }}
              </h5>
              <div class="invoice__company-info my-4">
                <p>{{ $root.businessData.data.postal_address }}</p>
                <p>{{ $root.businessData.data.postal_city }}</p>
              </div>
              <div class="invoice__company-contact">
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="MailIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">N/A</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">{{
                    $root.businessData.data.main_phone
                  }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="p-base" v-if="saleData !== null && saleData.products">
            <vs-table hoverFlat :data="saleData.products">
              <template slot="thead">
                <vs-th>ITEM</vs-th>
                <vs-th>RATE</vs-th>
                <vs-th>QUANTITY</vs-th>
                <vs-th>DISCOUNT</vs-th>
                <vs-th>TOTAL</vs-th>
              </template>

              <template>
                <vs-tr v-for="(tr, index) in saleData.products" :key="index">
                  <vs-td :data="tr.product_name"
                    >{{ tr.product_code }}<br />{{ tr.product_name }}
                  </vs-td>
                  <vs-td
                    :data="isWholesale ? tr.wholesale_price : tr.retail_price"
                    >{{
                      Number(
                        tr.sale.priceEach
                          ? tr.sale.priceEach
                          : isWholesale
                            ? tr.wholesale_price
                            : tr.retail_price,
                      ).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</vs-td
                  >
                  <vs-td :data="tr.q">{{ tr.q }}</vs-td>
                  <vs-td>
                    {{
                      Number(
                        tr.retail_price - tr.sale.priceEach > 0
                          ? tr.retail_price - tr.sale.priceEach || 0
                          : 0,
                      ).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</vs-td
                  >
                  <vs-td class="align-right">
                    {{
                      Number(
                        tr.q * tr.sale_price ||
                          tr.q *
                            (isWholesale
                              ? tr.wholesale_price
                              : tr.retail_price),
                      ).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</vs-td
                  >
                </vs-tr>
              </template>
            </vs-table>

            <vs-table hoverFlat class="w-1/2 ml-auto mt-4" :data="sale">
              <vs-tr>
                <th>SUBTOTAL</th>
                <td>
                  {{
                    Number(
                      parseFloat(saleData.amount + saleData.discount),
                    ).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </td>
              </vs-tr>
              <vs-tr>
                <th>
                  DISCOUNT ({{
                    parseFloat(
                      (saleData.discount /
                        (saleData.amount + saleData.discount)) *
                        100 || 0,
                    ).toLocaleString('en-KE')
                  }}%)
                </th>
                <td>
                  {{
                    Number(parseFloat(saleData.discount)).toLocaleString(
                      'en-KE',
                      {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      },
                    )
                  }}
                </td>
              </vs-tr>
              <vs-tr>
                <th>TOTAL</th>
                <td>
                  {{
                    Number(parseFloat(saleData.amount)).toLocaleString(
                      'en-KE',
                      {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      },
                    )
                  }}
                </td>
              </vs-tr>
            </vs-table>
          </div>

          <div class="invoice__footer text-right p-base">
            <p v-if="saleData && saleData.notes" class="mb-4">
              {{ saleData.notes }}
            </p>
            <p class="mb-4"></p>
          </div>
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import idb from '@/db/idb';

export default {
  data() {
    return {
      ipcRenderer: window.ipcRenderer,
      sale: {
        id: 0,
        amount: 0,
        customer: null,
        date: Date.now(),
        discount: 0,
        note: '',
        payment_method: {},
        user: {},
        products: [],
      },
    };
  },
  pouch: {
    saleData() {
      return {
        database: 'express',
        selector: { type: 'sale', _id: this.$route.params.id },
        first: true,
      };
    },
    logoData() {
      return {
        database: 'express',
        selector: { _id: 'logo' },
        first: true,
      };
    },
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
    isWholesale() {
      if (this.saleData && this.saleData.customer) {
        if (this.saleData.customer.hasOwnProperty('wholesale')) {
          return this.saleData.customer.wholesale || false;
        }
      }
      return false;
    },
  },
  methods: {
    parseDate(date) {
      return this.$moment(date).format('ddd DD MMM YYYY, h:mm:ss a');
    },
    returnSpace(cd, length) {
      var space = '';
      var count = length - cd.length;
      for (var i = 0; i < count; i++) {
        space += ' ';
      }
      return space;
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    printInvoice() {
      window.print();
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    getDate(x) {
      return this.$moment(x).format('DD MMM YYYY hh:mm:ss');
    },
    async print() {
      if (this.saleData.length < 1) return;
      let sale = this.saleData;
      this.showMessage(
        'icon-check-circle',
        'warning',
        'Processing Receipt',
        'Please wait as we process this receipt',
      );
      let cashierData = {};
      await this.$pouch.get(this.saleData.cashier, {}, 'express').then((r) => {
        cashierData = r;
      });
      window.ipcRenderer.send('saleReceipt', {
        logo: this.$root.logoBlob
          ? {
              type: this.$root.logoBlob.type,
              buffer: this.$root.logoBuffer,
            }
          : undefined,
        duplicate: false,
        business: this.$root.businessData.data,
        sale: {
          ...sale,
          id: this.$route.params.id ? this.$route.params.id.split(':')[1] : '',
          date: this.getDate(sale.date_created),
          cashier: cashierData,
        },
      });
      this.showMessage(
        'icon-check-circle',
        'success',
        'Receipt Processed',
        'Check your printer, the receipt has been generated and sent to the printer',
      );
    },
  },
  components: {},
  mounted() {
    this.$emit('setAppClasses', 'invoice-page');
  },
  async created() {
    // this.print();
  },
};
</script>

<style lang="scss">
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>
